import axios from 'axios'
const isProduction = process.env.NODE_ENV === "production"
// create an axios instance
const service = axios.create({
  // baseURL: 'https://cms.gg.com', // url = base url + request url
  // baseURL: 'http://10.10.3.91:5000',
  baseURL: isProduction ? location.origin : process.env.VUE_APP_BASE_API,
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    const res = response.data
    return res

    // if the custom code is not 20000, it is judged as an error.
    // if (res.code !== 20000) {
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
  },
  error => {
    console.log('err' + error) // for debug

    return Promise.reject(error)
  }
)

export default service
